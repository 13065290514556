import InventoryManagerSidebar from "./InventoryManagerSidebar";
//
const index = () => {
  return (
    <>
      <InventoryManagerSidebar />
    </>
  );
};

export default index;
