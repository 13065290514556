import img1 from '../../assets/IMG_0145.jpg'
import img2 from '../../assets/IMG_0228.jpg'
import img3 from '../../assets/IMG_0219.jpg'
import img4 from '../../assets/IMG_0002.jpg'
import img5 from '../../assets/IMG_0500.jpg'
import img6 from '../../assets/IMG_0005.jpg'

export const SliderData = [
    {
      image: img1
    },
    {
      image: img2       
    },
    {
      image: img3       
    },
    {
      image: img4       
    },
    {
      image: img5       
    },
    {
      image: img6       
    },
    
  ];