export const USER_ROLES = {
  ADMIN: "ADMIN",
  SUPPLIER: "SUPPLIER",
  RIDER: "RIDER",
  DELIVERYMANAGER:"DELIVERYMANAGER",
  CUSTOMER:"CUSTOMER",
  RESERVATIONMANAGER: "RESERVATIONMANAGER",
  INVENTORYMANAGER: "INVENTORYMANAGER",
  EMPLOYEE : "EMPLOYEE",
  CUSTOMERAFFAIRSMANAGER: "CUSTOMER_AFFAIRS_MANAGER",
  EMPLOYEEMANAGER:"EMPLOYEEMANAGER",
  CUSTOMERMANAGER:"CUSTOMERMANAGER",
  ORDERMANAGER:"ORDERMANAGER",
};
