import AdminSidebar from "./AdminSidebar";
//
const index = () => {
  return (
    <>
      <AdminSidebar />
    </>
  );
};

export default index;
