import RiderSidebar from "./RiderSidebar";
//
const index = () => {
  return (
    <>
      <RiderSidebar />
    </>
  );
};

export default index;
