import SupplierSidebar from "./SupplierSidebar";
//
const index = () => {
  return (
    <>
      <SupplierSidebar />
    </>
  );
};

export default index;
