import React from 'react'
import FAQlist from '../FAQlist'



export default function FAQComponent() {
  return (
    <div>
      <h1>FAQ Management</h1>
     <FAQlist />
      
    </div>
  )
};
